body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rg-card {
  margin: 20px;
}

* {
  caret-color: transparent;
}

.rg-card-image {
  border-radius: 10px;
  width: 350px;
  height: 480px;
  box-shadow: 0px 0px 3px 1px #ccc;
}


.rg-card-info {
  margin-top: 10px;
  min-height: 100px;
}


.rg-card-title {
  font-size: 24px;
  margin: 0px;
}


.rg-card-sub-title {
  font-size: 16px;
  margin: 0px;
}

.success-msg {
  color: #57A773;
  padding: 10px 15px;
}

.err-msg {
  color: #EE6352;
  padding: 10px 15px;
}

.about-title {
  font-size: 16px;
}

.bg-color {
  background-color: #235789;
}

.dl-button {
  border: none;
}
